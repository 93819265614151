<template>
    <div>
        <div class="h-screen overflow-auto bg-neutral-100 dark:bg-gray-800">
            <HeaderFrontend />
            <BaseSlideOver
                :header-color="'primary'"
                :title="$t(slideOverStore.title)"
                :description="$t(slideOverStore.description)"
                :model-value="slideOverStore.isSlideOverOpen"
                @update:modelValue="slideOverStore.setSlideOverOpen($event)">
                <Component :is="components[slideOverStore.component]" />
            </BaseSlideOver>
            <main>
                <div>
                    <!-- Your content -->
                    <ComingSoonPage
                        v-if="underConstruction"
                        hide-dashboard-link />
                    <slot v-else />
                </div>
            </main>
        </div>
    </div>
</template>

<script setup>
import AuthWrapper from "@/components/pages/auth/AuthWrapper.vue";
import FundingProgramsSelection from "@/components/global/FundingPrograms/FundingProgramsSelection";
import HeaderFrontend from "@/components/layouts/HeaderFrontend";
import {useWebsocketStore} from "@/store/websockets";
import {useSlideOverStore} from "@/store/slideOver";
import {useAuthStore} from "@/store/auth";

const authStore = useAuthStore()
const slideOverStore = useSlideOverStore()
const route = useRoute()

const components = {
    FundingProgramsSelection,
    AuthWrapper,
}

const underConstruction = computed(() => useRuntimeConfig()?.public?.underConstruction)

onMounted(() => {
    if (authStore.userData) {
        useWebsocketStore().init()
    }

    if (route.query?.resetPassword === "true") {
        slideOverStore.setSlideOverOpen(true);
        slideOverStore.setTitle('auth.resetPassword');
        slideOverStore.setDescription('');
        slideOverStore.setComponent('AuthWrapper');
        slideOverStore.setContext(null);
    }
})

useHead({
    bodyAttrs: {
        class: 'h-full bg-gray-50'
    },
    htmlAttrs: {
        class: 'h-full'
    }
})
</script>
