<template>
    <div>
        <AuthLogin
            v-if="authPageVisible === 'AuthLogin'"
            @auth-page-visible="$event => authPageVisible = $event" />
        <AuthRegister
            v-else-if="authPageVisible === 'AuthRegister'"
            @auth-page-visible="$event => authPageVisible = $event" />
        <AuthForgotPassword
            v-else-if="authPageVisible === 'AuthForgotPassword'"
            @auth-page-visible="$event => authPageVisible = $event" />
        <AuthResetPassword
            v-else-if="authPageVisible === 'AuthResetPassword'"
            @auth-page-visible="$event => authPageVisible = $event" />
    </div>
</template>

<script setup>
import AuthResetPassword from "@/components/pages/auth/AuthResetPassword.vue";
import AuthLogin from "@/components/pages/auth/AuthLogin.vue";
import AuthRegister from "@/components/pages/auth/AuthRegister.vue";
import AuthForgotPassword from "@/components/pages/auth/AuthForgotPassword.vue";

const {t} = useI18n()
const route = useRoute()

const authPageVisible = ref("AuthLogin")

watchEffect(() => {
    if ('register' in route.query) {
        authPageVisible.value = "AuthRegister"
    } else if ('forgotPassword' in route.query) {
        authPageVisible.value = "AuthForgotPassword"
    } else if (route.query.resetPassword === "true") {
        authPageVisible.value = "AuthResetPassword"
    }
})

</script>
